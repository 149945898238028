import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Spinner, Button, Alert, ProgressBar, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { useSavedDrugs } from './SavedDrugsContext';

const Profile = () => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  const [savedDrugs, setSavedDrugs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setSavedDrugsCount } = useSavedDrugs();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch saved drugs
        const getSavedDrugs = httpsCallable(functions, 'getsaveddrugs');
        const drugsResponse = await getSavedDrugs({ email: currentUser.email, locale: i18n.language });
        setSavedDrugs(drugsResponse.data.results);
        setSavedDrugsCount(drugsResponse.data.results.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser, i18n.language]);

  const getPdidHex = (pdid) => {
    if (typeof pdid === 'object' && pdid !== null) {
      const pdidArray = Object.values(pdid);
      return Buffer.from(pdidArray).toString('hex');
    }
    return '';
  };

  const handleDeleteDrug = async (pdid) => {
    try {
      const callableFunction = httpsCallable(functions, 'deleteSavedDrug');
      await callableFunction({ email: currentUser.email, pdid: getPdidHex(pdid) });
      fetchSavedDrugs();
    } catch (error) {
      console.error('Error deleting saved drug:', error);
    }
  };

  const renderLimitError = () => {
    if (!error) return null;

    return (
      <Alert variant="warning" className="mb-4">
        <Alert.Heading>
          {error.type === 'daily_limit_reached' ? t('dailyLimitReached') : t('trialLimitReached')}
        </Alert.Heading>
        <p>
          {error.type === 'daily_limit_reached'
            ? t('dailyLimitMessage', { limit: error.limit })
            : t('trialLimitMessage', { limit: error.limit })}
        </p>
        {error.type === 'trial_limit_reached' && (
          <div className="mt-2">
            <Button variant="primary" as={Link} to="/subscribe">
              {t('upgradeAccount')}
            </Button>
          </div>
        )}
      </Alert>
    );
  };

  const fetchSavedDrugs = async () => {
    try {
      const getSavedDrugs = httpsCallable(functions, 'getsaveddrugs');
      const drugsResponse = await getSavedDrugs({ email: currentUser.email, locale: i18n.language });
      setSavedDrugs(drugsResponse.data.results);
      setSavedDrugsCount(drugsResponse.data.results.length);
    } catch (error) {
      console.error('Error fetching saved drugs:', error);
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container className="mt-4 mb-4">
      <h2>{t('profile')}</h2>
      {renderLimitError()}
      <h3>{t('savedDrugs')}</h3>
      {savedDrugs.length === 0 ? (
        <p>{t('noSavedDrugs')}</p>
      ) : (
        <ListGroup>
          {savedDrugs.map((drug) => (
            <ListGroup.Item key={getPdidHex(drug.pdid)} className="d-flex justify-content-between align-items-center">
              <div>
                <strong>{drug.brand_name}</strong> - {drug.generic_name}
              </div>
              <div>
                <Button as={Link} to={`/drugs/details/${getPdidHex(drug.pdid)}`} variant="primary" className="me-2">
                  {t('viewDetails')}
                </Button>
                <Button variant="danger" onClick={() => handleDeleteDrug(drug.pdid)}>
                  {t('delete')}
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Container>
  );
};

export default Profile;