// utils.js
import React from 'react';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { Pagination } from 'react-bootstrap';

export const handleSort = (key, sortConfig, setSortConfig) => {
  let direction = 'asc';
  if (sortConfig.key === key && sortConfig.direction === 'asc') {
    direction = 'desc';
  }
  setSortConfig({ key, direction });
};

export const renderSortIcon = (key, sortConfig) => {
  if (sortConfig.key === key) {
    return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
  } else {
    return <FaSort />;
  }
};

export const renderPagination = (totalItems, itemsPerPage, activePage, setActivePage) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  if (totalPages <= 1) return null;

  const items = [];
  const maxVisiblePages = 5;
  let startPage = Math.max(1, activePage - Math.floor(maxVisiblePages / 2));
  let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(1, endPage - maxVisiblePages + 1);
  }

  for (let number = startPage; number <= endPage; number++) {
    items.push(
      <Pagination.Item key={number} active={number === activePage} onClick={() => setActivePage(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.First onClick={() => setActivePage(1)} disabled={activePage === 1} />
      <Pagination.Prev onClick={() => setActivePage(prev => Math.max(prev - 1, 1))} disabled={activePage === 1} />
      {items}
      <Pagination.Next onClick={() => setActivePage(prev => Math.min(prev + 1, totalPages))} disabled={activePage === totalPages} />
      <Pagination.Last onClick={() => setActivePage(totalPages)} disabled={activePage === totalPages} />
    </Pagination>
  );
};
