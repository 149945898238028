import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, ListGroup, Spinner, Button, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaChevronUp, FaChevronDown, FaInfoCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Buffer } from 'buffer';
import HerbalSupplementInteractions from './HerbalSupplementInteractions';
import References from './References';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { useLanguage } from './LanguageContext';
import { Link } from 'react-router-dom';

const HerbalSupplementDetails = () => {
  const { t, i18n } = useTranslation();
  const { dir } = useLanguage();
  const [details, setDetails] = useState(null);
  const [interactions, setInteractions] = useState([]);
  const [openGeneralInfo, setOpenGeneralInfo] = useState(true);
  const [openInteractions, setOpenInteractions] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filterSeverity, setFilterSeverity] = useState({
    major: true,
    moderate: true,
    minor: true
  });
  const [searchTerm, setSearchTerm] = useState('');
  const { uuid } = useParams();
  const [references, setReferences] = useState([]);
  const [highlightedReference, setHighlightedReference] = useState(null);
  const [openReferences, setOpenReferences] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInteractions = async () => {
      try {
        const callableFunction = httpsCallable(functions, 'interactionsall');
        const response = await callableFunction({ 
          uuid, language: i18n.language
        });

        if (response.data.error) {
          setError({
            type: response.data.limitType,
            limit: response.data.limit,
            current: response.data.current
          });
          return;
        }

        setDetails(response.data.interaction);
        setInteractions(response.data.ci_families);
        setReferences(response.data.ci_ref_unique_list);
      } catch (error) {
        console.error('Error fetching interactions:', error);
        setError({ type: 'general', message: error.message });
      }
    };

    fetchInteractions();
  }, [i18n.language, uuid]);

  if (error) {
    console.log('Error:', error);
    return (
      <Container className="mt-4">
        <Alert variant="warning">
          {error.type === 'daily_limit_reached' && (
            <>
              <h4>{t('dailyLimitReached')}</h4>
              <p>{t('dailyLimitMessage', { limit: error.limit, current: error.current })}</p>
            </>
          )}
          {error.type === 'trial_limit_reached' && (
            <>
              <h4>{t('trialLimitReached')}</h4>
              <p>{t('trialLimitMessage', { limit: error.limit, current: error.current })}</p>
            </>
          )}
          <Button as={Link} to="/account" variant="primary">
            {t('upgradeAccount')}
          </Button>
        </Alert>
      </Container>
    );
  }

  if (!details) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Container>
      <div className="page-header">
        <Button variant="link" className="btn-icon mt-2" onClick={handleBackClick}>
          {dir === 'rtl' ? <FaChevronRight id="header_back_glyphicon" /> : <FaChevronLeft id="header_back_glyphicon" />}
        </Button>
        <h3 className="drug-header">
          {t('herbalSupplementDetailsFor')}
          <span className="mx-1">{details.interaction}</span>
        </h3>
      </div>
      
      <div className="mt-3">
        <div className="card mb-3">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <h6><img src="/images/drug_info_small_icon.png" alt="General Info" /> {t('generalInfo')} </h6>
              <Button
                variant="link"
                onClick={() => setOpenGeneralInfo(!openGeneralInfo)}
                aria-controls="collapse-general-info"
                aria-expanded={openGeneralInfo}
              >
                {openGeneralInfo ? <FaChevronUp /> : <FaChevronDown />}
              </Button>
            </div>
            {openGeneralInfo && (
              <div id="collapse-general-info">
                <p>{details.description}</p>
                <p><strong>{t('botanicalName')}:</strong> {details.botanical_name}</p>
                <p><strong>{t('chineseName')}:</strong> {details.chinese_name}</p>
                <p><strong>{t('additionalNames')}:</strong> {details.additional_names}</p>
                <p><strong>{t('partInUse')}:</strong> {details.part_in_use}</p>
                <p><strong>{t('usageRestrictions')}:</strong> {details.usage_restrictions}</p>
              </div>
            )}
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <HerbalSupplementInteractions
              interactions={interactions}
              filterSeverity={filterSeverity}
              setFilterSeverity={setFilterSeverity}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              openInteractions={openInteractions}
              setOpenInteractions={setOpenInteractions}
              setHighlightedReference={setHighlightedReference}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <References 
              references={references} 
              openReferences={openReferences} 
              setOpenReferences={setOpenReferences} 
              highlightedReference={highlightedReference}
            />
          </div>
        </div>

        <div className="well well-sm note-info mt-3">
          <FaInfoCircle className="note" /> {t('herbalSupplementInfoDisclaimer')}
        </div>
        
        <div id="back_to_top" className="mt-3 text-center">
          <Button variant="link" onClick={() => window.scrollTo(0, 0)}>
            <FaChevronUp /> <span>{t('backToTop')}</span>
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default HerbalSupplementDetails;
