import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Spinner } from 'react-bootstrap';
const PrivateRoute = ({ children }) => {
  const { currentUser, hasAccess, checkingSubscription } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Show loading or spinner while checking subscription
  if (checkingSubscription) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!hasAccess && location.pathname !== '/account') {
    return <Navigate to="/subscription-expired" />;
  }

  return children;
};

export default PrivateRoute;
