import React from 'react';
import { Navbar, Nav, Button, Container, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { signOut } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase'; 
import { FaGlobe, FaUserCircle, FaMedkit, FaUserCog } from 'react-icons/fa';
import { useLanguage } from './LanguageContext';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { useEffect, useState } from 'react';
import { useSavedDrugs } from './SavedDrugsContext';
import SubscriptionStatus from './components/SubscriptionStatus';

const Navigation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language, changeLanguage } = useLanguage();
  const { savedDrugsCount, setSavedDrugsCount } = useSavedDrugs();
  const { currentUser, isAdmin } = useAuth();
  useEffect(() => {
    const fetchSavedDrugsCount = async () => {
      try {
        const callableFunction = httpsCallable(functions, 'getsaveddrugs');
        const response = await callableFunction({ email: currentUser.email, locale: language });
        setSavedDrugsCount(response.data.results.length);
      } catch (error) {
        console.error('Error fetching saved drugs count:', error);
      }
    };

    if (currentUser) {
      fetchSavedDrugsCount();
    }
  }, [currentUser, language, setSavedDrugsCount]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  };

  return (
    <Navbar bg="primary" expand="lg" className="navbar-custom fixed-top">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img src="/images/white-transparent.png" alt={t('drugBalancerLogo')} className="d-none d-lg-block" />
          <img src="/images/white-transparent.png" alt={t('drugBalancerLogo')} className="d-lg-none" style={{ height: '38px' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon" style={{ filter: 'brightness(0) invert(1)' }} />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            {currentUser && <SubscriptionStatus user={currentUser} />}
            {isAdmin && (
              <Nav.Link as={Link} to="/admin" className="text-light">
                <FaUserCog /> <span className="ms-1">{t('adminPanel')}</span>
              </Nav.Link>
            )}
            {currentUser ? (
              <>
                <Nav.Link as={Link} to="/profile" className="text-light me-3">
                  <FaMedkit /> <span className="ms-1">{savedDrugsCount}</span>
                </Nav.Link>
                <NavDropdown
                  align="end"
                  className="text-light"
                  title={<span className="text-light"><FaUserCircle /> {currentUser.email}</span>}
                  id="user-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/profile">{t('profile')}</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/account">{t('accountSettings')}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleLogout}>{t('logout')}</NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <Nav.Link as={Link} to="/login" className="text-light">
                {t('loginRegister')}
              </Nav.Link>
            )}
            <NavDropdown
              align="end"
              className="text-light ms-3"
              title={
                <span className="text-light">
                  <FaGlobe /> {language === 'en' ? 'EN' : 'HE'}
                </span>
              }
              id="language-dropdown"
            >
              <NavDropdown.Item 
                onClick={() => handleLanguageChange('en')}
                active={language === 'en'}
              >
                English
              </NavDropdown.Item>
              <NavDropdown.Item 
                onClick={() => handleLanguageChange('he')}
                active={language === 'he'}
              >
                עברית
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;