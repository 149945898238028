import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import SubscriptionExpired from './SubscriptionExpired';

const AccountPage = () => {
  const { currentUser, isSubscribed, subscriptionExpiryDate, hasAccess } = useAuth();
  const { t } = useTranslation();
  const [viewLimits, setViewLimits] = useState(null);

  useEffect(() => {
    const fetchViewLimits = async () => {
      try {
        const getViewLimits = httpsCallable(functions, 'checkviewlimits');
        const limitsResponse = await getViewLimits();
        console.log('View Limits Response:', limitsResponse.data);
        setViewLimits(limitsResponse.data);
      } catch (error) {
        console.error('Error fetching view limits:', error);
      }
    };

    if (currentUser && hasAccess) {
      fetchViewLimits();
    }
  }, [currentUser, hasAccess]);

  const renderViewLimits = () => {
    if (!viewLimits) return null;

    const drugData = viewLimits.viewLimits?.drug;
    const herbData = viewLimits.viewLimits?.herb;

    if (!drugData || !herbData) return null;

    return (
      <Card className="mb-4">
        <Card.Header as="h5">{t('viewLimits')}</Card.Header>
        <Card.Body>
          <div className="limit-cards">
            {/* Drug Views */}
            <Card className="mb-3">
              <Card.Body>
                <h5>{t('drugViews')}</h5>
                <ProgressBar 
                  now={viewLimits.isSubscribed ? drugData.today : drugData.total} 
                  max={drugData.limit} 
                  variant={drugData.remaining > 0 ? "primary" : "danger"}
                  label={`${viewLimits.isSubscribed ? drugData.today : drugData.total}/${drugData.limit}`}
                />
                <div className="mt-2">
                  <small>
                    {viewLimits.isSubscribed 
                      ? t('dailyViewsRemaining', { 
                          remaining: drugData.remaining, 
                          total: drugData.limit 
                        })
                      : t('trialViewsRemaining', { 
                          remaining: drugData.remaining, 
                          total: drugData.limit 
                        })}
                  </small>
                </div>
              </Card.Body>
            </Card>

            {/* Herb Views */}
            <Card className="mb-3">
              <Card.Body>
                <h5>{t('herbViews')}</h5>
                <ProgressBar 
                  now={viewLimits.isSubscribed ? herbData.today : herbData.total} 
                  max={herbData.limit} 
                  variant={herbData.remaining > 0 ? "primary" : "danger"}
                  label={`${viewLimits.isSubscribed ? herbData.today : herbData.total}/${herbData.limit}`}
                />
                <div className="mt-2">
                  <small>
                    {viewLimits.isSubscribed 
                      ? t('dailyViewsRemaining', { 
                          remaining: herbData.remaining, 
                          total: herbData.limit 
                        })
                      : t('trialViewsRemaining', { 
                          remaining: herbData.remaining, 
                          total: herbData.limit 
                        })}
                  </small>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Card.Body>
      </Card>
    );
  };

  if (!hasAccess) {
    return <SubscriptionExpired />;
  }

  return (
    <Container className="mt-5 mb-5">
      <Row>
        <Col md={8} className="mx-auto">
          <Card className="mb-4">
            <Card.Header as="h5">{t('accountSettings')}</Card.Header>
            <Card.Body>
              <Card.Title>{t('subscriptionStatus')}</Card.Title>
              <Card.Text>
                {hasAccess ? t('activeSubscription') : t('inactiveSubscription')}
              </Card.Text>
              {subscriptionExpiryDate && (
                <Card.Text>
                  {t('subscriptionExpiryDate')}: {new Date(subscriptionExpiryDate).toLocaleDateString()}
                </Card.Text>
              )}
            </Card.Body>
          </Card>
          {renderViewLimits()}
        </Col>
      </Row>
    </Container>
  );
};

export default AccountPage;