import React, { useEffect } from 'react';
import { ListGroup, Button, Collapse, Form, Row, Col, Table, DropdownButton, Dropdown } from 'react-bootstrap';
import { FaLemon, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Supplements = ({
  supplements,
  nutrients,
  filterKosher,
  setFilterKosher,
  filterVegan,
  setFilterVegan,
  filterNutrient,
  setFilterNutrient,
  supplementLimit,
  setSupplementLimit,
  openSupplements,
  setOpenSupplements,
}) => {
  const { t } = useTranslation();

  const handleKosherChange = () => {
    setFilterKosher(!filterKosher);
  };

  const handleVeganChange = () => {
    setFilterVegan(!filterVegan);
  };

  const handleNutrientChange = (e) => {
    setFilterNutrient(e.target.value);
  };

  const handleLimitChange = (limit) => {
    setSupplementLimit(limit);
  };

  // Filter supplements
  const filteredSupplements = supplements.filter(supplement => 
    (!filterKosher || supplement.is_kosher) &&
    (!filterVegan || supplement.is_vegan) &&
    (!filterNutrient || supplement.nutrient_name === filterNutrient)
  );

  // Sort supplements by percent_rdv_4_years_and_above in descending order
  const sortedSupplements = [...filteredSupplements].sort((a, b) => 
    b.percent_rdv_4_years_and_above - a.percent_rdv_4_years_and_above
  );

  // Apply limit after sorting
  const limitedSupplements = supplementLimit 
    ? sortedSupplements.slice(0, supplementLimit) 
    : sortedSupplements;

  // Filter out undefined keys and empty arrays from nutrients
  const filteredNutrients = Object.entries(nutrients || {}).reduce((acc, [key, value]) => {
    if (key !== 'undefined' && Array.isArray(value) && value.length > 0) {
      acc[key] = value;
    }
    return acc;
  }, {});

  useEffect(() => {
    if (Object.keys(filteredNutrients).length > 0 && !filterNutrient) {
      const firstNutrient = Object.values(filteredNutrients).flat()[0];
      setFilterNutrient(firstNutrient);
    }
  }, [filteredNutrients, filterNutrient]);

  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <h6><img src="/images/food_source_small_icon.png" alt="Food Sources" /> {t('foodSources')}</h6>
        <div className="d-flex align-items-center">
          <span className="me-2 section-total">{supplements.length}</span>
          <Button
            variant="link"
            onClick={() => setOpenSupplements(!openSupplements)}
            aria-controls="collapse-supplements"
            aria-expanded={openSupplements}
          >
            {openSupplements ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </div>
      </div>
      <Collapse in={openSupplements}>
        <div id="collapse-supplements">
          <Form className="mb-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="kosher"
                  label={t('kosher')}
                  checked={filterKosher}
                  onChange={handleKosherChange}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="vegan"
                  label={t('vegan')}
                  checked={filterVegan}
                  onChange={handleVeganChange}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Control
                  as="select"
                  value={filterNutrient || ''}
                  onChange={handleNutrientChange}
                >
                  {/* <option value="">{t('selectNutrient')}</option> */}
                  {Object.values(filteredNutrients).flat().map((nutrient, index) => (
                    <option key={index} value={nutrient}>{nutrient}</option>
                  ))}
                </Form.Control>
              </Col>
              <Col xs="auto">
                <DropdownButton
                  id="dropdown-supplement-limit"
                  title={supplementLimit ? t('top10') : t('showAll')}
                  onSelect={handleLimitChange}
                >
                  <Dropdown.Item eventKey={10}>{t('top10')}</Dropdown.Item>
                  <Dropdown.Item eventKey={null}>{t('showAll')}</Dropdown.Item>
                </DropdownButton>
              </Col>
            </Row>
          </Form>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('foodSource')}</th>
                <th>{t('amountPer100g')}</th>
                <th>{t('percentRDV')}</th>
              </tr>
            </thead>
            <tbody>
              {limitedSupplements.map((supplement, index) => (
                <tr key={index}>
                  <td>{supplement.food_source}</td>
                  <td>{supplement.amount_per_100_gr}</td>
                  <td>{supplement.percent_rdv_4_years_and_above}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default Supplements;
