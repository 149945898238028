import React, { useState, useEffect } from 'react';
import { Table, Badge, Button, Collapse, Card, ListGroup } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp, FaClock, FaBan, FaExclamationCircle, FaUserMd, FaFlask, FaInfoCircle, FaCheckCircle, FaQuestionCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { renderSortIcon } from './utils';


const InteractionsTable = ({ interactions, sortConfig, handleSort, columns, setHighlightedReference, activePage }) => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState({});


  const renderIcon = (templateType) => {
    switch (templateType) {
      case 't_time':
        return <FaClock />;
      case 't_stop':
        return <FaBan />;
      case 't_alert':
        return <FaExclamationCircle />;
      case 't_medical':
        return <FaUserMd />;
      case 't_dosage':
        return <FaFlask />;
      case 't_info':
        return <FaInfoCircle />;
      case 't_ok':
        return <FaCheckCircle />;
      case 't_unknown':
      default:
        return <FaQuestionCircle />;
    }
  };


  useEffect(() => {
    setExpandedRows({});
  }, [activePage]);

  const getLink = (item) => {
    return `/herbalsupplements/details/${item.uuid_val}`;
  };

  const getLinkDrugs = (value, field) => {
    return `/?q=${value}&field=${field}`;
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const handleReferenceClick = (refNum) => {
    setHighlightedReference(refNum);
  };

  const renderDetailItem = (label, value) => {
    if (!value) return null;
    return (
      <ListGroup.Item>
        <strong>{label}:</strong> {Array.isArray(value) ? value.join(', ') : value}
      </ListGroup.Item>
    );
  };
  

  const renderReferences = (references) => {
    if (!references || references.length === 0) return null;
    return (
      <p className="mt-2">
        <strong>{t('references')}</strong> {references.map((ref, index) => (
          <a
            key={index}
            href={`#reference-${ref}`}
            onClick={() => handleReferenceClick(ref)}
          >
            {ref}
          </a>
        )).reduce((prev, curr) => [prev, ', ', curr])}
      </p>
    );
  };

  const renderInteractionDetail = (interaction) => (
    <Card className="mb-4">
      <Card.Body>
        <ListGroup variant="flush">
          {renderDetailItem(t("riskCategory"), t(interaction.severity))}
          {renderDetailItem(t("majorConsumption"), interaction.consumption_type)}
          {renderDetailItem(t("supplementOrFood"), interaction.interaction)}
          {renderDetailItem(t("plantPartInUse"), interaction.part_in_use)}
          {renderDetailItem(t("additionalNames"), interaction.additional_names)}
          {renderDetailItem(t("categoryName"), interaction.category_name)}
          {renderDetailItem(t("genericName"), interaction.generic_name)}
        </ListGroup>
        
        {interaction.complete_status === 'must_not' && (
          <Card bg="danger" text="white" className="mt-3">
            <Card.Body>
              <Card.Title>{t('warning')}</Card.Title>
              <Card.Text>{t('mustNotBeConsumed')}</Card.Text>
            </Card.Body>
          </Card>
        )} 

        <div className="mt-3">
          <h6>{t('interactionEffects')}:</h6>
          <p>{interaction.interaction_template}</p>
          {interaction.family_interaction_details.map((detail, index) => (
            <div key={index}>
              <p>{detail.interaction_effects}</p>
              {renderReferences(detail.references)}
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => handleSort('severity')} style={{ cursor: 'pointer' }}>
              {t('severity')} {renderSortIcon('severity', sortConfig)}
            </th>
            {columns.map((col) => (
              <th key={col.key} onClick={() => handleSort(col.key)} style={{ cursor: 'pointer' }}>
                {t(col.label)} {renderSortIcon(col.key, sortConfig)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {interactions.map((interaction, index) => (
            <React.Fragment key={index}>
              <tr>
                <td>
                  <Button 
                    variant="link" 
                    onClick={() => toggleRowExpansion(index)}
                    aria-label={expandedRows[index] ? "Collapse" : "Expand"}
                  >
                    {expandedRows[index] ? <FaChevronUp /> : <FaChevronDown />}
                  </Button>
                </td>
                <td>
                <Badge bg={interaction.severity === 'major' ? 'danger' : interaction.severity === 'moderate' ? 'warning' : 'info'}>
                    {renderIcon(interaction.template_type)} {t(interaction.severity)}
                </Badge>
                </td>
                {columns.map((col) => (
                  <td key={col.key}>
                    {col.key === 'interaction' ? (
                      <Link to={getLink(interaction)}>{interaction[col.key]}</Link>
                    ) : (
                      <Link to={getLinkDrugs(interaction[col.key],col.key)}>{interaction[col.key]}</Link>
                    )}
                  </td>
                ))}
              </tr>
              {expandedRows[index] && (
                <tr>
                  <td colSpan={columns.length + 2}>
                    <div className="p-3">
                      {renderInteractionDetail(interaction)}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default InteractionsTable;
