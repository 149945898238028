import React, { useState, useEffect } from 'react';
import { ListGroup, Button, Collapse, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FaExclamationTriangle, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import InteractionsTable from './InteractionsTable';
import { handleSort, renderPagination } from './utils';

const Interactions = ({
  interactions,
  filterSeverity,
  setFilterSeverity,
  searchTerm,
  setSearchTerm,
  sortConfig,
  setSortConfig,
  openInteractions,
  setOpenInteractions,
  setHighlightedReference,
}) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (!sortConfig.key) {
      setSortConfig({ key: 'severity', direction: 'asc', secondaryKey: 'generic_name', secondaryDirection: 'asc' });
    }
  }, []);

  // Filter out null interactions
  const validInteractions = interactions.filter(interaction => interaction.interaction !== null);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setActivePage(1); // Reset to first page
  };
  const handleFilterChange = (severity) => {
    setFilterSeverity((prevState) => ({
      ...prevState,
      [severity]: !prevState[severity],
    }));
    setActivePage(1);
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setActivePage(1);
  };
  const filteredInteractions = validInteractions
  .filter(
    (interaction) =>
      filterSeverity[interaction.severity] &&
      ((interaction.interaction && interaction.interaction.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (interaction.botanical_name && interaction.botanical_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (interaction.chinese_name && interaction.chinese_name.toLowerCase().includes(searchTerm.toLowerCase())))
  )
  .sort((a, b) => {
    if (sortConfig.key === 'severity') {
      return sortConfig.direction === 'asc' ? a.severity_rank - b.severity_rank : b.severity_rank - a.severity_rank;
    }
    const severityOrder = { major: 1, moderate: 2, minor: 3 };
    return severityOrder[a.severity] - severityOrder[b.severity];
  });
  const sortedInteractions = [...filteredInteractions].sort((a, b) => {
    if (sortConfig.key === 'severity') {
      const severityOrder = { major: 1, moderate: 2, minor: 3 };
      const severityComparison = sortConfig.direction === 'asc' 
        ? severityOrder[a.severity] - severityOrder[b.severity]
        : severityOrder[b.severity] - severityOrder[a.severity];
      
      if (severityComparison === 0) {
        // Use a fallback property if generic_name is not available
        const aValue = a[sortConfig.secondaryKey] || a.interaction || '';
        const bValue = b[sortConfig.secondaryKey] || b.interaction || '';
        return sortConfig.secondaryDirection === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
      
      return severityComparison;
    }
    
    const aValue = a[sortConfig.key] || '';
    const bValue = b[sortConfig.key] || '';
    
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }
    
    if (aValue < bValue) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const columns = [
    { key: 'interaction', label: t('commonName') },
    { key: 'botanical_name', label: t('botanicalName') },
    { key: 'chinese_name', label: t('chineseName') },
  ];

  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <h6>
        <img src="/images/ci_small_icon.png" alt="Interactions" /> {t('interactions')}  
        </h6>
        <div className="d-flex align-items-center">
          <span className="me-2 section-total">{validInteractions.length}</span>
          <Button
            variant="link"
            onClick={() => setOpenInteractions(!openInteractions)}
            aria-controls="collapse-interactions"
            aria-expanded={openInteractions}
          >
            {openInteractions ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </div>
      </div>
      <Collapse in={openInteractions}>
        <div id="collapse-interactions">
          <Form className="mb-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="major"
                  label={t('major')}
                  checked={filterSeverity.major}
                  onChange={() => handleFilterChange('major')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="moderate"
                  label={t('moderate')}
                  checked={filterSeverity.moderate}
                  onChange={() => handleFilterChange('moderate')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="minor"
                  label={t('minor')}
                  checked={filterSeverity.minor}
                  onChange={() => handleFilterChange('minor')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                </InputGroup>
              </Col>
              <Col xs="auto">
                <Form.Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={filteredInteractions.length}>{t('showAll')}</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
          <InteractionsTable
            interactions={sortedInteractions.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)}
            sortConfig={sortConfig}
            handleSort={(key) => {
              if (key === sortConfig.key) {
                setSortConfig(prevConfig => ({
                  ...prevConfig,
                  direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
                }));
              } else {
                setSortConfig({
                  key,
                  direction: 'asc',
                  secondaryKey: 'generic_name',
                  secondaryDirection: 'asc'
                });
              }
            }}
            columns={columns}
            setHighlightedReference={setHighlightedReference}
            activePage={activePage}

          />
          {renderPagination(filteredInteractions.length, itemsPerPage, activePage, setActivePage)}
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default Interactions;
