import React, { useEffect, useRef } from 'react';
import { ListGroup, Button, Collapse } from 'react-bootstrap';
import { FaBook, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const References = ({ references, openReferences, setOpenReferences, highlightedReference }) => {
  const { t } = useTranslation();
  const refs = useRef([]);

  useEffect(() => {
    if (highlightedReference && refs.current[highlightedReference]) {
      refs.current[highlightedReference].scrollIntoView({ behavior: 'smooth', block: 'start' });
      refs.current[highlightedReference].classList.add('highlight');
      
    }
  }, [highlightedReference]);

  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <h6>
        <img src="/images/ref_icon.png" alt="References" /> {t('references')} 
        </h6>
        <div className="d-flex align-items-center">
          <span className="me-2 section-total">{references.length}</span>
          <Button
            variant="link"
            onClick={() => setOpenReferences(!openReferences)}
            aria-controls="collapse-references"
            aria-expanded={openReferences}
          >
            {openReferences ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </div>
      </div>
      <Collapse in={openReferences}>
        <div id="collapse-references">
          {references.map((reference, index) => (
            <div
              key={index}
              id={`reference-${reference.ref_num}`}
              ref={(el) => (refs.current[reference.ref_num] = el)}
              className={`reference-item ${highlightedReference === reference.ref_num ? 'highlight' : ''}`}
            >
              <p><small className="mx-1">{`[${reference.ref_num}]`}</small><strong></strong> {reference.research}</p>
            </div>
          ))}
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default References;
