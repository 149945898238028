import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container my-2">
      <h1>תנאי שימוש</h1>
      <p>
        למידע נוסף על תנאי השימוש, אנא בקרו באתר{' '}
        <a 
          href="https://www.perelmantrade.com/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          EBR
        </a>
      </p>
      {/* Rest of your existing terms content */}
    </div>
  );
};

export default TermsOfUse;