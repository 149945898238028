import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container my-2">
      <h1>מדיניות פרטיות</h1>
      <p>
        למידע נוסף על מדיניות הפרטיות שלנו, אנא בקרו באתר{' '}
        <a 
          href="https://www.perelmantrade.com/" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          EBR
        </a>
      </p>
      
    </div>
  );
};

export default PrivacyPolicy;