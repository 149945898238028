import React, { useState, useEffect } from 'react';
import { ListGroup, Button, Collapse, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { FaExclamationTriangle, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import InteractionsTable from './InteractionsTable';
import { handleSort, renderPagination } from './utils';

const HerbalSupplementInteractions = ({ interactions, filterSeverity, setFilterSeverity, searchTerm, setSearchTerm, sortConfig, setSortConfig, openInteractions, setOpenInteractions, setHighlightedReference }) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10); // State for items per page
  const [activePage, setActivePage] = useState(1);

  const handleFilterChange = (severity) => {
    setFilterSeverity(prevState => ({
      ...prevState,
      [severity]: !prevState[severity]
    }));
    setActivePage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setActivePage(1); // Reset to first page
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setActivePage(1); // Reset to first page
  };

  const filteredInteractions = interactions.filter(interaction =>
    filterSeverity[interaction.severity] &&
    (
      (interaction.generic_name && interaction.generic_name.toLowerCase().includes(searchTerm.toLowerCase())) || !interaction.generic_name ||
      // (interaction.brand_name && interaction.brand_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      // (interaction.family_name && interaction.family_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (interaction.indication && interaction.indication.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  );
  const sortedInteractions = [...filteredInteractions].sort((a, b) => {
    if (sortConfig.key === 'severity') {
      const severityOrder = { major: 1, moderate: 2, minor: 3 };
      const severityComparison = sortConfig.direction === 'asc' 
        ? severityOrder[a.severity] - severityOrder[b.severity]
        : severityOrder[b.severity] - severityOrder[a.severity];
      
      if (severityComparison === 0) {
        return sortConfig.secondaryDirection === 'asc'
          ? a[sortConfig.secondaryKey].localeCompare(b[sortConfig.secondaryKey])
          : b[sortConfig.secondaryKey].localeCompare(a[sortConfig.secondaryKey]);
      }
      
      return severityComparison;
    }
    
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

const columns = [
  { key: 'generic_name', label: 'genericName' },
  { key: 'family_name', label: 'familyName' }
];

useEffect(() => {
  if (!sortConfig.key) {
    setSortConfig({ key: 'severity', direction: 'asc', secondaryKey: 'generic_name', secondaryDirection: 'asc' });
  }
}, []);

  return (
       <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <h6>
        <img src="/images/ci_small_icon.png" alt="Interactions" /> {t('interactions')} 
        </h6>
        <div className="d-flex align-items-center">
          <span className="me-2 section-total">{interactions.length}</span>
          <Button
            variant="link"
            onClick={() => setOpenInteractions(!openInteractions)}
            aria-controls="collapse-interactions"
            aria-expanded={openInteractions}
          >
            {openInteractions ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </div>
      </div>
      <Collapse in={openInteractions}>
        <div id="collapse-interactions">
        <Form className="mb-3">
            <Row className="align-items-center">
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="major"
                  label={t('major')}
                  checked={filterSeverity.major}
                  onChange={() => handleFilterChange('major')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="moderate"
                  label={t('moderate')}
                  checked={filterSeverity.moderate}
                  onChange={() => handleFilterChange('moderate')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <Form.Check
                  type="checkbox"
                  id="minor"
                  label={t('minor')}
                  checked={filterSeverity.minor}
                  onChange={() => handleFilterChange('minor')}
                  className="mb-2"
                />
              </Col>
              <Col xs="auto">
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                </InputGroup>
              </Col>
              <Col xs="auto">
                <Form.Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={filteredInteractions.length}>{t('showAll')}</option>
                </Form.Select>
              </Col>
            </Row>
          </Form>
          <InteractionsTable
            interactions={sortedInteractions.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)}
            sortConfig={sortConfig}
handleSort={(key) => {
  if (key === sortConfig.key) {
    setSortConfig(prevConfig => ({
      ...prevConfig,
      direction: prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  } else {
    setSortConfig({
      key,
      direction: 'asc',
      secondaryKey: 'generic_name',
      secondaryDirection: 'asc'
    });
  }
}}
            columns={columns}
            setHighlightedReference={setHighlightedReference}
            activePage={activePage}
          />
          {renderPagination(filteredInteractions.length, itemsPerPage, activePage, setActivePage)}
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default HerbalSupplementInteractions;
