import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { useTranslation } from 'react-i18next';
import { Container, ListGroup, Spinner } from 'react-bootstrap';
import { useAuth } from './AuthContext';

const SavedDrugs = () => {
  const { t, i18n } = useTranslation();
  const { currentUser } = useAuth();
  const [savedDrugs, setSavedDrugs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSavedDrugs = async () => {
      try {
        const callableFunction = httpsCallable(functions, 'getSavedDrugs');
        const response = await callableFunction({ email: currentUser.email, locale: i18n.language });
        setSavedDrugs(response.data.results);
      } catch (error) {
        console.error('Error fetching saved drugs:', error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchSavedDrugs();
    }
  }, [currentUser, i18n.language]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Container>
      <h2>{t('savedDrugs')}</h2>
      <ListGroup>
        {savedDrugs.map((drug) => (
          <ListGroup.Item key={drug.pdid}>
            <strong>{drug.brand_name}</strong> - {drug.generic_name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Container>
  );
};

export default SavedDrugs;