import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const sendEmail = httpsCallable(functions, 'sendcontactemail');
            const emailData = {
                name: formData.name,
                email: formData.email,
                message: formData.message
            };
            console.log('Sending email data:', emailData);
            await sendEmail(emailData);
            setShowAlert(true);
            setFormData({ name: '', email: '', message: '' }); // Clear the form
            setTimeout(() => setShowAlert(false), 5000); // Hide alert after 5 seconds
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Failed to send message. Please try again later.');
        }
    };

    return (
        <Container className="contact-page mb-5 mt-5">
            {showAlert && (
                <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                    {t('messageSentSuccessfully')}
                </Alert>
            )}
            <Row>
                <Col>
                <Row>
                <Col>
                    <h2>{t('contactUs')}</h2>
                    <p>{t('contactUsDescription')}</p>
                    <p>{t('phoneNumber')}</p>
                    <p>{t('address')}</p>
                    <p>{t('dbemail')}</p>
                    <p>{t('companyName')}</p>
                </Col>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            <Form.Label>{t('name')}</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formEmail">
                            <Form.Label>{t('email')}</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formMessage">
                            <Form.Label>{t('message')}</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button className="my-2" variant="primary" type="submit">
                            {t('send')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default Contact;