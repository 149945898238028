import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaBan, FaUserMd, FaClock, FaExclamationCircle, FaFlask, FaQuestionCircle, FaCheckCircle, FaPlusCircle } from 'react-icons/fa';

const LegendModal = ({ show, onHide }) => {
  const { t } = useTranslation();

  const severityClasses = {
    major: 'text-danger',
    moderate: 'text-warning',
    minor: 'text-info',
    unknown: 'text-secondary'
  };

  const renderSeverity = (severity) => (
    <Row className="mb-2">
      <Col sm={2}>
        <span className={`${severityClasses[severity]} fw-bold`}>
          <small>{t(severity)}</small>
        </span>
      </Col>
      <Col sm={10}>
        <small>{t(`${severity}Description`)}</small>
      </Col>
    </Row>
  );

  const renderIcon = (Icon, description) => (
    <Row className="mb-2">
      <Col sm={1}>
        <small><Icon /></small>
      </Col>
      <Col sm={11}>
        <small>{description}</small>
      </Col>
    </Row>
  );

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t('severityClassification')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <small>
        {t('contraindicationsDescription')}
        {renderSeverity('major')}
        {renderSeverity('moderate')}
        {renderSeverity('minor')}
        {renderSeverity('unknown')}
        </small>
        <small className="mt-3 mb-3">{t('medicationChangeDisclaimer')}</small>

        <h6>{t('icons')}</h6>
        <small>{t('iconDescription')}</small>

        {renderIcon(FaBan, t('banIconDescription'))}
        {renderIcon(FaUserMd, t('doctorIconDescription'))}
        {renderIcon(FaClock, t('clockIconDescription'))}
        {renderIcon(FaExclamationCircle, t('cautionIconDescription'))}
        {renderIcon(FaFlask, t('flaskIconDescription'))}
        {renderIcon(FaQuestionCircle, t('questionIconDescription'))}
        {renderIcon(FaCheckCircle, t('checkIconDescription'))}

        <small className="mt-3">
          {t('plusIconDescription')} <FaPlusCircle />
        </small>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LegendModal;