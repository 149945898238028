import React, { createContext, useState, useContext } from 'react';

const SavedDrugsContext = createContext();

export const SavedDrugsProvider = ({ children }) => {
  const [savedDrugsCount, setSavedDrugsCount] = useState(0);

  return (
    <SavedDrugsContext.Provider value={{ savedDrugsCount, setSavedDrugsCount }}>
      {children}
    </SavedDrugsContext.Provider>
  );
};

export const useSavedDrugs = () => useContext(SavedDrugsContext);