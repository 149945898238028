import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Container, Button, Spinner, Alert } from 'react-bootstrap';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import DrugInfo from './DrugInfo';
import Interactions from './Interactions';
import Nutrients from './Nutrients';
import Supplements from './Supplements';
import References from './References';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { FaChevronUp, FaChevronLeft, FaChevronRight, FaMedkit, FaQuestionCircle } from 'react-icons/fa';
import { useLanguage } from './LanguageContext';
import { useAuth } from './AuthContext';
import { useSavedDrugs } from './SavedDrugsContext';
import LegendModal from './LegendModal';

const DrugDetails = () => {
  const { pdid } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { dir } = useLanguage();
  const { currentUser } = useAuth();
  const [drugData, setDrugData] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [openDrugInfo, setOpenDrugInfo] = useState(true);
  const [openInteractions, setOpenInteractions] = useState(true);
  const [openNutrients, setOpenNutrients] = useState(true);
  const [openReferences, setOpenReferences] = useState(false);
  const [openSupplements, setOpenSupplements] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [filterSeverity, setFilterSeverity] = useState({
    major: true,
    moderate: true,
    minor: true
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filterKosher, setFilterKosher] = useState(false);
  const [filterVegan, setFilterVegan] = useState(false);
  const [filterNutrient, setFilterNutrient] = useState('');
  const [supplementLimit, setSupplementLimit] = useState(10);
  const [highlightedReference, setHighlightedReference] = useState(null);
  const [saveMessage, setSaveMessage] = useState('');
  const { savedDrugsCount, setSavedDrugsCount } = useSavedDrugs();
  const [showLegend, setShowLegend] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDrugDetails = async () => {
      try {
        const pdidBuffer = Buffer.from(pdid, 'hex');
        const callableFunction = httpsCallable(functions, 'drugdetailsall');
        const response = await callableFunction({ 
          pdid: pdidBuffer.toString('hex'), 
          locale: i18n.language 
        });

        if (response.data.error) {
          setError({
            type: response.data.limitType,
            limit: response.data.limit,
            current: response.data.current
          });
          return;
        }

        setDrugData(response.data);
      } catch (error) {
        console.error('Error fetching drug details:', error);
        setError({ type: 'general', message: error.message });
      }
    };

    if (pdid && i18n.language) {
      fetchDrugDetails();
    }
  }, [pdid, i18n.language]);

  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const pdidBuffer = Buffer.from(pdid, 'hex');
        const callableFunction = httpsCallable(functions, 'supplementsbypdid');
        const response = await callableFunction({ 
          pdid: pdidBuffer.toString('hex'),
          kosher: filterKosher,
          vegan: filterVegan,
          locale: i18n.language,
          nutrient: filterNutrient,
          limit: supplementLimit
        });
        setSupplements(response.data.results);
      } catch (error) {
        console.error('Error fetching supplements:', error);
      }
    };

    if (pdid && i18n.language && drugData) {
      fetchSupplements();
    }
  }, [pdid, i18n.language, filterKosher, filterVegan, filterNutrient, supplementLimit, drugData]);

  const handleSaveDrug = async () => {
    try {
      const callableFunction = httpsCallable(functions, 'savedrug');
      const result = await callableFunction({ email: currentUser.email, pdid });
      if (result.data.alreadySaved) {
        setSaveMessage(t('drugAlreadySaved'));
      } else {
        setSaveMessage(t('drugSaved'));
        setSavedDrugsCount(prevCount => prevCount + 1);
      }
      setTimeout(() => setSaveMessage(''), 3000); // Clear message after 3 seconds
    } catch (error) {
      console.error('Error saving drug:', error);
      setSaveMessage(t('errorSavingDrug'));
      setTimeout(() => setSaveMessage(''), 3000); // Clear message after 3 seconds
    }
  };

  if (error) {
    console.log('Error:', error);
    return (
      <Container className="mt-4">
        <Alert variant="warning">
          {error.type === 'daily_limit_reached' && (
            <>
              <h4>{t('dailyLimitReached')}</h4>
              <p>{t('dailyLimitMessage', { limit: error.limit, current: error.current })}</p>
            </>
          )}
          {error.type === 'trial_limit_reached' && (
            <>
              <h4>{t('trialLimitReached')}</h4>
              <p>{t('trialLimitMessage', { limit: error.limit, current: error.current })}</p>
            </>
          )}
         
        </Alert>
      </Container>
    );
  }

  if (!drugData) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">{t('loading')}</span>
        </Spinner>
      </div>
    );
  }

  const handleBackClick = () => {
    navigate(-1);
  };


  return (
    <Container>
      <div className="page-header">
        <Button variant="link" className="btn-icon mt-4" onClick={handleBackClick}>
          {dir === 'rtl' ? <FaChevronRight id="header_back_glyphicon" /> : <FaChevronLeft id="header_back_glyphicon" />}
        </Button>
        <Button variant="primary" onClick={handleSaveDrug} className="mt-4">
        <FaMedkit /> 
        
      </Button>
      <Button variant="primary" className="btn-icon mt-4 mx-1" onClick={() => setShowLegend(true)}>
          {t('legend')} <FaQuestionCircle />
        </Button>
      {saveMessage && (
        <div className="alert alert-success mt-2" role="alert">
          {saveMessage}
        </div>
      )}
        <h3 className="drug-header">
          {drugData.drug.brand_name}
        </h3>
 
      </div>

      <div className="mt-3">
        <div className="card mb-3">
          <div className="card-body">
            <DrugInfo 
              detail={drugData.drug} 
              families={drugData.drug_families}
              categories={drugData.drug_categories}
              indications={drugData.drug_indications}
              openDrugInfo={openDrugInfo} 
              setOpenDrugInfo={setOpenDrugInfo} 
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <Interactions
              interactions={drugData.family_interactions}
              filterSeverity={filterSeverity}
              setFilterSeverity={setFilterSeverity}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              openInteractions={openInteractions}
              setOpenInteractions={setOpenInteractions}
              setHighlightedReference={setHighlightedReference}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <Nutrients 
              nutrients={drugData.nutrient_families} 
              openNutrients={openNutrients} 
              setOpenNutrients={setOpenNutrients} 
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <Supplements
              supplements={supplements}
              nutrients={drugData.nutrient_families}
              filterKosher={filterKosher}
              setFilterKosher={setFilterKosher}
              filterVegan={filterVegan}
              setFilterVegan={setFilterVegan}
              filterNutrient={filterNutrient}
              setFilterNutrient={setFilterNutrient}
              supplementLimit={supplementLimit}
              setSupplementLimit={setSupplementLimit}
              openSupplements={openSupplements}
              setOpenSupplements={setOpenSupplements}
            />
          </div>
        </div>

        <div className="card mb-3">
          <div className="card-body">
            <References 
              references={drugData.ci_ref_unique_list} 
              openReferences={openReferences} 
              setOpenReferences={setOpenReferences} 
              highlightedReference={highlightedReference}
            />
          </div>
        </div>
      </div>
      <div id="back_to_top" className="mt-3 text-center">
        <Button variant="link" onClick={() => window.scrollTo(0, 0)}>
          <FaChevronUp /> <span>{t('backToTop')}</span>
        </Button>
      </div>
      <LegendModal show={showLegend} onHide={() => setShowLegend(false)} />
    </Container>
  );
};

export default DrugDetails;
