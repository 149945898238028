import React from 'react';
import { ListGroup, Button, Collapse } from 'react-bootstrap';
import { FaCapsules, FaSearch, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DrugInfo = ({ detail, families, categories, indications, openDrugInfo, setOpenDrugInfo }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLinkClick = (e, value, field) => {
    e.preventDefault();
    navigate(`/?q=${value}&field=${field}`);
  };

  const renderList = (items, field) => {
    return items.map((item, index) => (
      <span key={index}>
        <a href="#" onClick={(e) => handleLinkClick(e, item, field)}> {item} <FaSearch /></a>
        {index < items.length - 1 ? ' / ' : ''}
      </span>
    ));
  };

  return (
    <ListGroup.Item className="drug-detail-item">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <img src="/images/drug_info_small_icon.png" alt="Drug Info" /> {t('drugInfo')}
        </div>
        <Button
          variant="link"
          onClick={() => setOpenDrugInfo(!openDrugInfo)}
          aria-controls="collapse-drug-info"
          aria-expanded={openDrugInfo}
        >
          {openDrugInfo ? <FaChevronUp /> : <FaChevronDown />}
        </Button>
      </div>
      <Collapse in={openDrugInfo}>
        <div id="collapse-drug-info">
          <p><strong>{t('family')}:</strong> {renderList(families, 'family_name')}</p>
          <p><strong>{t('category')}:</strong> {renderList(categories, 'category_name')}</p>
          <p><strong>{t('indication')}:</strong> {renderList(indications, 'indication')}</p>
          <p>
            <strong>{t('genericName')}:</strong> 
            {detail.generic_name.split(/[/,]/).map((name, index) => (
              <React.Fragment key={index}>
                {index > 0 && ', '}
                <a href="#" onClick={(e) => handleLinkClick(e, name.trim(), 'generic_name')}>{name.trim()} <FaSearch /> </a>
              </React.Fragment>
            ))}
          </p>
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default DrugInfo;