import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Container, Table, Button, Modal, Form, Collapse, Row, Col, Card, Pagination } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AdminPanel = () => {
  const { isAdmin } = useAuth();
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [expiryDate, setExpiryDate] = useState('');
  const [isUserAdmin, setIsUserAdmin] = useState(false);

  const [usageLogs, setUsageLogs] = useState([]);

  const [openSections, setOpenSections] = useState({
    subscriptions: true,
    groupedReport: true,
    detailedReport: true,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [logsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [allFilteredLogs, setAllFilteredLogs] = useState([]);

  
  const formatDate = (dateInput) => {
    if (!dateInput) return '';
    const date = new Date(dateInput);
    if (isNaN(date.getTime())) return dateInput; // Return original input if invalid date
    return date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0];
  };


  const emailOptions = useMemo(() => {
    return userSubscriptions.map((sub) => ({ value: sub.email, label: sub.email }));
  }, [userSubscriptions]);

  // Serialize selectedEmails for use in dependencies
  const selectedEmailsValues = useMemo(
    () => selectedEmails.map((option) => option.value).join(','),
    [selectedEmails]
  );

  const fetchUserSubscriptions = useCallback(async () => {
    if (isAdmin) {
      try {
        const getUserSubscriptions = httpsCallable(functions, 'getusersubscriptions');
        const result = await getUserSubscriptions();
        setUserSubscriptions(result.data.results);
      } catch (error) {
        console.error('Error fetching user subscriptions:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [isAdmin]);

  const fetchUsageLogs = useCallback(async () => {
    if (isAdmin) {
      try {
        const getUserUsageLogs = httpsCallable(functions, 'getUserUsageLogs');
        const result = await getUserUsageLogs({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          page: currentPage,
          logsPerPage: logsPerPage,
          emails: selectedEmails.map((option) => option.value),
        });
        setUsageLogs(result.data.results);
        setTotalPages(Math.ceil(result.data.totalCount / logsPerPage));
      } catch (error) {
        console.error('Error fetching user usage logs:', error);
      }
    }
  }, [isAdmin, startDate, endDate, currentPage, logsPerPage, selectedEmailsValues]);

  const fetchAllFilteredLogs = useCallback(async () => {
    if (isAdmin) {
      try {
        const getUserUsageLogs = httpsCallable(functions, 'getUserUsageLogs');
        const result = await getUserUsageLogs({
          startDate: startDate ? startDate.toISOString() : null,
          endDate: endDate ? endDate.toISOString() : null,
          page: 1,
          logsPerPage: 1000000, // A large number to fetch all logs
          emails: selectedEmails.map((option) => option.value),
        });
        return result.data.results;
      } catch (error) {
        console.error('Error fetching all filtered user usage logs:', error);
        return [];
      }
    }
    return [];
  }, [isAdmin, startDate, endDate, selectedEmailsValues]);

  const handleExcelExport = async () => {
    const allFilteredLogs = await fetchAllFilteredLogs();
    const worksheet = XLSX.utils.json_to_sheet(allFilteredLogs);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Usage Logs');
    XLSX.writeFile(workbook, 'usage_logs.xlsx');
  };

  useEffect(() => {
    if (isAdmin) {
      fetchUserSubscriptions();
    }
  }, [isAdmin, fetchUserSubscriptions]);

  useEffect(() => {
    if (isAdmin) {
      fetchUsageLogs();
      fetchAllFilteredLogs().then(setAllFilteredLogs);
    }
  }, [isAdmin, fetchUsageLogs, fetchAllFilteredLogs]);

  const handleEditClick = async (user) => {
    setSelectedUser(user);
    setExpiryDate(user.expiry_date);

    try {
      const checkUserAdminStatus = httpsCallable(functions, 'checkuseradminstatus');
      const result = await checkUserAdminStatus({ email: user.email });
      setIsUserAdmin(result.data.isAdmin);
    } catch (error) {
      console.error('Error checking user admin status:', error);
      setIsUserAdmin(false);
    }

    setShowEditModal(true);
  };

  const handleSave = async () => {
    try {
      const updateUserSubscription = httpsCallable(functions, 'updateusersubscription');
      await updateUserSubscription({
        email: selectedUser.email,
        expiry_date: expiryDate,
        admin: isUserAdmin,
      });
      setUserSubscriptions((prev) =>
        prev.map((user) =>
          user.email === selectedUser.email
            ? { ...user, expiry_date: expiryDate, admin: isUserAdmin, updated_at: new Date().toISOString() }
            : user
        )
      );

      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating user subscription:', error);
    }
  };

  const groupUsageLogs = (logs) => {
    return logs.reduce((acc, log) => {
      if (!acc[log.email]) {
        acc[log.email] = {};
      }
      if (!acc[log.email][log.action_type]) {
        acc[log.email][log.action_type] = 0;
      }
      acc[log.email][log.action_type] += 1;
      return acc;
    }, {});
  };

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const handleDateChange = (date, dateType) => {
    if (dateType === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
    setCurrentPage(1);
  };

  const handleEmailFilterChange = (selectedOptions) => {
    setSelectedEmails(selectedOptions);
    setCurrentPage(1);
  };

  const renderGroupedUsageReport = () => {
    const groupedLogs = groupUsageLogs(allFilteredLogs);
    
    const chartData = {
      labels: Object.keys(groupedLogs),
      datasets: [
        {
          label: 'Total Actions',
          data: Object.values(groupedLogs).map(actions => 
            Object.values(actions).reduce((sum, count) => sum + count, 0)
          ),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        },
      ],
    };

    const chartOptions = {
      indexAxis: 'y', // This makes the bars horizontal
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Grouped Usage Report',
        },
      },
      scales: {
        x: {
          beginAtZero: true,
        },
      },
    };

    return (
      <div>
        <Bar data={chartData} options={chartOptions} />
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>{t('email')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedLogs).map(([email, actions], index) => (
              <tr key={index}>
                <td>{email}</td>
                <td>
                  {Object.entries(actions).map(([action, count], actionIndex) => (
                    <div key={actionIndex}>
                      {action}: {count}
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  const renderDetailedUsageReport = () => {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3>{t('detailedUsageReport')}</h3>
          <Button variant="success" onClick={handleExcelExport}>
            {t('exportToExcel')}
          </Button>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t('email')}</th>
              <th>{t('actionType')}</th>
              <th>{t('details')}</th>
              <th>{t('timestamp')}</th>
            </tr>
          </thead>
          <tbody>
            {usageLogs.map((log, index) => (
              <tr key={index}>
                <td>{log.email}</td>
                <td>{log.action_type}</td>
                <td>{JSON.stringify(log.details)}</td>
                <td>{formatDate(log.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {totalPages > 0 && (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {[...Array(Math.min(totalPages, 5))].map((_, i) => {
              const pageNumber = currentPage + i - Math.min(currentPage - 1, 2);
              return (
                pageNumber > 0 && pageNumber <= totalPages && (
                  <Pagination.Item
                    key={pageNumber}
                    active={pageNumber === currentPage}
                    onClick={() => setCurrentPage(pageNumber)}
                  >
                    {pageNumber}
                  </Pagination.Item>
                )
              );
            })}
            <Pagination.Next
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
          </Pagination>
        )}
      </div>
    );
  };

  if (!isAdmin) {
    return <Container>{t('adminAccessDenied')}</Container>;
  }

  if (loading) {
    return <Container>{t('loading')}</Container>;
  }

  return (
    <Container className="mt-3">
      <h1>{t('adminPanel')}</h1>

      <Card className="mb-3 mt-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h2>{t('userSubscriptions')}</h2>
            <Button variant="link" onClick={() => toggleSection('subscriptions')}>
              {openSections.subscriptions ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </div>
        </Card.Header>
        <Collapse in={openSections.subscriptions}>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>{t('email')}</th>
                  <th>{t('expiryDate')}</th>
                  <th>{t('createdAt')}</th>
                  <th>{t('updatedAt')}</th>
                  <th>{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {userSubscriptions.map((subscription) => (
                  <tr key={subscription.email}>
                    <td>{subscription.email}</td>
                    <td>{formatDate(subscription.expiry_date)}</td>
                    <td>{formatDate(subscription.created_at)}</td>
                    <td>{formatDate(subscription.updated_at)}</td>
                    <td>
                      <Button variant="primary" onClick={() => handleEditClick(subscription)}>
                        {t('edit')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Collapse>
      </Card>

      <Card className="mt-4 mb-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h2>{t('usageReports')}</h2>
            <Button
              variant="link"
              onClick={() => setOpenSections((prev) => ({ ...prev, groupedReport: !prev.groupedReport }))}
            >
              {openSections.groupedReport ? <FaChevronUp /> : <FaChevronDown />}
            </Button>
          </div>
        </Card.Header>
        <Collapse in={openSections.groupedReport}>
          <Card.Body>
            <Form className="mb-3">
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{t('startDate')}</Form.Label>
                    <DatePicker
                      className="form-control mx-1"
                      selected={startDate}
                      onChange={(date) => handleDateChange(date, 'start')}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{t('endDate')}</Form.Label>
                    <DatePicker
                      className="form-control mx-1"
                      selected={endDate}
                      onChange={(date) => handleDateChange(date, 'end')}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>{t('filterByEmail')}</Form.Label>
                    <Select
                      className="mx-1"
                      isMulti
                      options={emailOptions}
                      value={selectedEmails}
                      onChange={handleEmailFilterChange}
                      placeholder={t('selectEmails')}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            {renderGroupedUsageReport()}
            {renderDetailedUsageReport()}
          </Card.Body>
        </Collapse>
      </Card>

      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('editSubscription')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control type="email" value={selectedUser?.email} readOnly />
            </Form.Group>
            <Form.Group controlId="formExpiryDate">
              <Form.Label>{t('expiryDate')}</Form.Label>
              <Form.Control
                type="date"
                value={expiryDate.split('T')[0]}
                onChange={(e) => setExpiryDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formAdmin">
              <Form.Check
                type="checkbox"
                label={t('makeAdmin')}
                checked={isUserAdmin}
                onChange={(e) => setIsUserAdmin(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            {t('close')}
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {t('saveChanges')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default AdminPanel;