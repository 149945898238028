import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LanguageProvider, useLanguage } from './LanguageContext';
import './styles/main.css';
import DrugSearch from './DrugSearch';
import DrugDetails from './DrugDetails';
import HerbalSupplementDetails from './HerbalSupplementDetails';
import Login from './Login';
import Navigation from './Navbar'; 
import PrivateRoute from './PrivateRoute';
import DataHierarchy from './DataHierarchy';
import EditTable from './EditTable';
import './i18n';
import { useTranslation } from 'react-i18next';
import SavedDrugs from './SavedDrugs';
import About from './About';
import Contact from './Contact';
import { Link, useNavigate } from 'react-router-dom';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import 'firebaseui/dist/firebaseui.css';
import SubscriptionExpired from './SubscriptionExpired';
import AccountPage from './AccountPage';
import Profile from './Profile';
import { SavedDrugsProvider } from './SavedDrugsContext';
import AdminRoute from './AdminRoute';
import AdminPanel from './AdminPanel';
import { useAuth } from './AuthContext';

const AppContent = () => {
  const { dir, language } = useLanguage();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { currentUser } = useAuth();

  const loadCSS = (href) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.id = 'dynamic-css';
    document.head.appendChild(link);
  };
  
  const unloadCSS = () => {
    const existingLink = document.getElementById('dynamic-css');
    if (existingLink) {
      existingLink.remove();
    }
  };
  
  useEffect(() => {
    document.documentElement.dir = dir;
    document.documentElement.lang = language;
    i18n.changeLanguage(language);
  
    unloadCSS();
    if (dir === 'rtl') {
      loadCSS('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.rtl.min.css');
    } else {
      loadCSS('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css');
    }
  
    return () => {
      unloadCSS();
    };
  }, [dir, language, i18n]);

  return (
    <div className="App">
      <Navigation />
      <div className="container-fluid main-content">
        <Routes>
          <Route path="/" element={<PrivateRoute><DrugSearch /></PrivateRoute>} />
          <Route path="/drugs/details/:pdid" element={<PrivateRoute><DrugDetails /></PrivateRoute>} />
          <Route path="/herbalsupplements/details/:uuid" element={<PrivateRoute><HerbalSupplementDetails /></PrivateRoute>} />
          <Route path="/login" element={<Login />} />
          <Route path="/data-hierarchy" element={<PrivateRoute><DataHierarchy /></PrivateRoute>} />
          <Route path="/edit/:tableName" element={<PrivateRoute><EditTable /></PrivateRoute>} />
          <Route path="/saved-drugs" element={<PrivateRoute><SavedDrugs /></PrivateRoute>} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/subscription-expired" element={<SubscriptionExpired />} />
          <Route path="/account" element={<PrivateRoute><AccountPage /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route 
  path="/admin" 
  element={
    <AdminRoute>
      <AdminPanel />
    </AdminRoute>
  } 
/>
        </Routes>
      </div>
      <footer className="footer page-row">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-auto mb-3">
              <ul className="list-unstyled">
              <li><Link to="/">{t('drugBalancer')}</Link></li>
              <li><Link to="/account">{t('accountSettings')}</Link></li>

              </ul>
            </div>
            <div className="col-auto mb-3">
              <ul className="list-unstyled">
                <li><Link to="/contact">{t('contactUs')}</Link></li>
                <li><Link to="/about">{t('aboutUs')}</Link></li>
              </ul>
            </div>
            <div className="col-auto mb-3">
              <ul className="list-unstyled">
              <li><Link to="/terms-of-use">{t('termsOfUse')}</Link></li>
                <li><Link to="/privacy-policy">{t('privacyPolicy')}</Link></li>
              </ul>
            </div>
            {/* <div className="col-auto mb-3">
              <ul className="list-unstyled">
              <li><Link to="/pricing">{t('pricing')}</Link></li>

                <li><span className="text-muted">© {t('bodyBalancerPro')}</span></li>
              </ul>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  
  return (
    <SavedDrugsProvider>

    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
    </SavedDrugsProvider>

  );
};

export default App;
