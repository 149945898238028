import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Table, Button, Pagination, Modal, Form, Container, Row, Col } from 'react-bootstrap';

const tables = [
  { name: 'drug', relationships: [{ table: 'drug_family', field: 'drug_family_bridge.drug_id' }, { table: 'user_profile', field: 'profile_drug_bridge.drug_id' }, { table: 'yarpa_code_pdid_bridge', field: 'yarpa_code_pdid_bridge.pdid' }] },
  { name: 'drug_family', relationships: [{ table: 'drug', field: 'drug_family_bridge.family_id' }, { table: 'nutrient', field: 'family_nutrients_depleted_bridge.family_id' }, { table: 'drug_family_interaction', field: 'drug_family_interaction.family_id' }] },
  { name: 'nutrient', relationships: [{ table: 'drug_family', field: 'family_nutrients_depleted_bridge.nutrient_id' }, { table: 'nutrient_source', field: 'nutrient_source.nutrient_id' }] },
  { name: 'interaction', relationships: [{ table: 'drug_family_interaction', field: 'drug_family_interaction.interaction_id' }] },
  { name: 'drug_family_interaction', relationships: [{ table: 'drug_family', field: 'drug_family_interaction.family_id' }, { table: 'interaction', field: 'drug_family_interaction.interaction_id' }, { table: 'drug_family_interaction_details', field: 'drug_family_interaction_details.drug_family_interaction_id' }] },
  { name: 'drug_family_interaction_details', relationships: [{ table: 'drug_family_interaction', field: 'drug_family_interaction_details.drug_family_interaction_id' }, { table: 'drug_family_interaction_details_research', field: 'drug_family_interaction_details_research.drug_family_interaction_details_id' }] },
  { name: 'drug_family_interaction_details_research', relationships: [{ table: 'drug_family_interaction_details', field: 'drug_family_interaction_details_research.drug_family_interaction_details_id' }] },
  { name: 'user_account', relationships: [{ table: 'user_profile', field: 'user_profile.user_account_id' }, { table: 'cg_transaction', field: 'cg_transaction.user_account_auth_id' }, { table: 'coupon_user_bridge', field: 'coupon_user_bridge.user_auth_id' }] },
  { name: 'user_profile', relationships: [{ table: 'user_account', field: 'user_profile.user_account_id' }, { table: 'drug', field: 'profile_drug_bridge.profile_id' }] },
  { name: 'cg_transaction', relationships: [{ table: 'user_account', field: 'cg_transaction.user_account_auth_id' }] },
  { name: 'yarpa_code_pdid_bridge', relationships: [{ table: 'drug', field: 'yarpa_code_pdid_bridge.pdid' }] },
  { name: 'coupon', relationships: [{ table: 'coupon_user_bridge', field: 'coupon_user_bridge.coupon_code' }] },
  { name: 'coupon_user_bridge', relationships: [{ table: 'coupon', field: 'coupon_user_bridge.coupon_code' }, { table: 'user_account', field: 'coupon_user_bridge.user_auth_id' }] },
  { name: 'nutrient_source', relationships: [{ table: 'nutrient', field: 'nutrient_source.nutrient_id' }] }
];

const EditTable = () => {
  const { tableName } = useParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [formData, setFormData] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  const [relatedData, setRelatedData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    fetchData(currentPage);
  }, [tableName, currentPage]);

  const fetchData = async (page) => {
    try {
      const response = await axios.get(`http://localhost:5001/${tableName}?page=${page}&pageSize=${pageSize}`);
      setData(response.data.results);
      setTotalPages(response.data.totalPages);
      // console.log('Fetched data:', response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleInputChange = (e, column) => {
    const newFormData = { ...formData, [column]: e.target.value };
    setFormData(newFormData);
  };

  const handleEdit = (row) => {
    setFormData(row);
    setEditRowId(row.id);
    setShowEditModal(true);
  };

  const handleSubmit = async () => {
    try {
      await axios.put(`http://localhost:5001/${tableName}/${editRowId}`, formData);
      setShowEditModal(false);
      fetchData(currentPage);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const fetchRelatedData = async (row) => {
    setSelectedRow(row);
    const relatedTables = tables.find(table => table.name === tableName)?.relationships || [];
    const relatedDataPromises = relatedTables.map(rel => {
      return axios.get(`http://localhost:5001/${rel.table}?${rel.field.split('.').pop()}=${row.id}`);
    });

    try {
      const relatedDataResponses = await Promise.all(relatedDataPromises);
      const newRelatedData = {};
      relatedTables.forEach((rel, index) => {
        newRelatedData[rel.table] = relatedDataResponses[index].data.results;
      });
      setRelatedData(newRelatedData);
    } catch (error) {
      console.error('Error fetching related data:', error);
    }
  };

  const renderPagination = () => {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
          {number}
        </Pagination.Item>
      );
    }
    return (
      <Pagination className="justify-content-center flex-wrap">{items}</Pagination>
    );
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={12} md={10} lg={8}>
          <h2 className="my-4">Edit {tableName}</h2>
          <div style={{ overflowX: 'auto' }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {data.length > 0 && Object.keys(data[0]).map((column) => <th key={column}>{column}</th>)}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <tr key={row.id} onClick={() => fetchRelatedData(row)}>
                    {Object.keys(row).map((column) => (
                      <td key={column}>{typeof row[column] === 'object' ? JSON.stringify(row[column]) : row[column]}</td>
                    ))}
                    <td>
                      <Button variant="primary" onClick={() => handleEdit(row)}>Edit</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-center mt-3">
            {renderPagination()}
          </div>
        </Col>
      </Row>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Row</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {formData && Object.keys(formData).map((column) => (
              <Form.Group key={column}>
                <Form.Label>{column}</Form.Label>
                <Form.Control
                  type="text"
                  value={formData[column]}
                  onChange={(e) => handleInputChange(e, column)}
                />
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
        </Modal.Footer>
      </Modal>

      {selectedRow && (
        <div className="mt-4">
          <h3>Related Data for {selectedRow.id}</h3>
          {Object.keys(relatedData).map((table, index) => (
            <div key={index} className="mt-3">
              <h4>{table}</h4>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {relatedData[table].length > 0 && Object.keys(relatedData[table][0]).map((column) => <th key={column}>{column}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {relatedData[table].map((row) => (
                    <tr key={row.id}>
                      {Object.keys(row).map((column) => (
                        <td key={column}>{typeof row[column] === 'object' ? JSON.stringify(row[column]) : row[column]}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}
        </div>
      )}
    </Container>
  );
};

export default EditTable;
