import { initializeApp } from "firebase/app";
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import * as firebaseui from 'firebaseui';
import { httpsCallable } from 'firebase/functions';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCEnp8Qp7LbIK_jHhez_EqWFKH9P6qvqiU",
    authDomain: "body-balancer.firebaseapp.com",
    projectId: "body-balancer",
    storageBucket: "body-balancer.appspot.com",
    messagingSenderId: "1001797999900",
    appId: "1:1001797999900:web:fff3584b60598d38ea6ed9"
  };

// Initialize Firebase services
const app = initializeApp(firebaseConfig);

// Initialize App Check
if (process.env.NODE_ENV === 'development') {
  // Debug token for development
  console.log('Setting debug token for development');
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

// Initialize App Check with reCAPTCHA v3
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LeL0m0qAAAAAIuQXfeeTzm58gdNILdgqEf9V23E'),
  isTokenAutoRefreshEnabled: true,
  // Add error handling
  onError: (error) => {
    console.error('App Check error:', error);
  }
});

const auth = getAuth(app);
const functions = getFunctions(app, 'me-west1');

// Firebase UI configuration
const uiConfig = {
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
  ],
  signInSuccessUrl: '/',
  tosUrl: '/terms-of-service',
  privacyPolicyUrl: '/privacy-policy',
  signInFlow: 'popup'
};
console.log('uiConfig', uiConfig);
// Initialize Firebase UI
const ui = new firebaseui.auth.AuthUI(auth);

// Development environment setup
// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, 'localhost', 5001);
// }

// Warm-up function (commented out for now)
// const warmUpFunctions = httpsCallable(functions, 'warmUpFunctions');

// Add this function to handle score assessment
// const assessRecaptchaScore = async (token, action) => {
//   try {
//     console.log('Assessing reCAPTCHA score...');
//     const createAssessment = httpsCallable(functions, 'createRecaptchaAssessment');
//     const result = await createAssessment({
//       token,
//       recaptchaAction: action
//     });
//     console.log('reCAPTCHA score assessment result:', result.data);
//     return result.data.score;
//   } catch (error) {
//     console.error('Error assessing reCAPTCHA score:', error);
//     return null;
//   }
// };
export { app, auth, functions, ui, uiConfig };
