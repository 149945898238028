  import React, { useState, useCallback, useEffect, useRef } from 'react';
  import { useNavigate, useLocation } from 'react-router-dom';
  import { Container, Form, Row, Col, Card, ListGroup, InputGroup, Button, Tabs, Tab } from 'react-bootstrap';
  import debounce from 'lodash.debounce';
  import { FaSearch, FaTimes, FaInfoCircle } from 'react-icons/fa';
  import { Buffer } from 'buffer';
  import { useTranslation } from 'react-i18next';
  import { httpsCallable } from 'firebase/functions';
  import { functions } from './firebase';

  const DrugSearch = () => {
    const { t, i18n } = useTranslation();
    const [query, setQuery] = useState('');
    const [searchMode, setSearchMode] = useState('drugs');
    const [results, setResults] = useState([]);
    const [selectedResult, setSelectedResult] = useState(null);
    const [selectedResultField, setSelectedResultField] = useState('');
    const [noResults, setNoResults] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isInitialLoad = useRef(true);

    const fieldNameTranslations = {
      generic_name: 'genericName',
      brand_name: 'brandName',
      family_name: 'familyName',
      category_name: 'categoryName',
      Interactions: 'Interactions',
      botanical_name: 'botanicalName',
      chinese_name: 'chineseName',
      additional_names: 'additionalNames',
      // Add other field names as needed
    };

    const highlightMatch = (text, query) => {
      if (!query) return text;
      const parts = text.split(new RegExp(`(${query})`, 'gi'));
      return parts.map((part, index) => 
        part.toLowerCase() === query.toLowerCase() ? 
          <mark key={index}>{part}</mark> : 
          part
      );
    };

    const fetchDetailsFromQuery = useCallback(async (searchQuery, field = 'generic_name') => {
      try {
        const endpoint = searchMode === 'drugs' ? 'drugdetails' : 'interactiondetails';
        const callableFunction = httpsCallable(functions, endpoint);
        const response = await callableFunction({ 
          field, 
          value: searchQuery, 
          locale: i18n.language 
        });

        if (response.data.results.length === 0) {
          setNoResults(true);
        } else {
          setSelectedResult(response.data.results);
          setSelectedResultField(field);
          setNoResults(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, [searchMode, i18n.language]);

    useEffect(() => {
      if (isInitialLoad.current) {
        const params = new URLSearchParams(location.search);
        const q = params.get('q');
        const field = params.get('field') || 'generic_name';
        if (q) {
          setQuery(q);
          fetchDetailsFromQuery(q, field);
        }
        isInitialLoad.current = false;
      }
    }, [location.search, fetchDetailsFromQuery]);

              // const endpoint = searchMode === 'drugs' ? 'drugs/search' : 'interactions/search';
          // const response = await axios.get(`http://localhost:5001/${endpoint}`, {
          //   params: { q: searchQuery, limit: 10 }
          // });

    const debouncedSearch = useCallback(
      debounce(async (searchQuery) => {
        try {

          const endpoint = searchMode === 'drugs' ? 'drugsearch' : 'interactionsearch';
          const callableFunction = httpsCallable(functions, endpoint);
          const response = await callableFunction({ 
            q: searchQuery, 
            limit: 10, 
            locale: i18n.language  
          });
    
          if (response.data.results.length === 0) {
            setNoResults(true);
          } else {
            setResults(response.data.results);
            setNoResults(false);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching data:', error);
        }
      }, 300),
      [searchMode, i18n.language]
    );

    const handleSearchChange = (e) => {
      const newQuery = e.target.value;
      setQuery(newQuery);
      setResults([]);
      setSelectedResult(null);
      setNoResults(false);
      if (newQuery.length >= 2) {
        setIsLoading(true);
        debouncedSearch(newQuery);
      } else {
        setIsLoading(false);
      }
    };

    const handleSearchModeChange = (mode) => {
      setSearchMode(mode);
      setQuery('');
      setResults([]);
      setSelectedResult(null);
    };


    const handleSelectResult = async (result) => {
      try {
        const endpoint = searchMode === 'drugs' ? 'drugdetails' : 'interactiondetails';
        const callableFunction = httpsCallable(functions, endpoint);
        const response = await callableFunction({ 
          field: result.matched_field, 
          value: result.name,
          locale: i18n.language
        });
        console.log(response);
        setQuery(result.name);
        setSelectedResult(response.data.results);
        setSelectedResultField(result.matched_field);
        setResults([]);
      } catch (error) {
        console.error('Error fetching detailed data:', error);
      }
    };

    // const handleSelectResult = async (result) => {
    //   try {
    //     const endpoint = searchMode === 'drugs' ? 'drugs/details' : 'interactions/details';
    //     const response = await axios.get(`http://localhost:5001/${endpoint}`, {
    //       params: { field: result.matched_field, value: result.name }
    //     });
    //     setQuery(result.name);
    //     setSelectedResult(response.data.results);
    //     setSelectedResultField(result.matched_field);
    //     setResults([]);
    //   } catch (error) {
    //     console.error('Error fetching detailed data:', error);
    //   }
    // };

    const groupedResults = results.reduce((acc, item) => {
      const { matched_field } = item;
      if (!acc[matched_field]) {
        acc[matched_field] = [];
      }
      acc[matched_field].push(item);
      return acc;
    }, {});

    const navigateToDetails = (pdid) => {
      try {
        if (pdid && typeof pdid === 'object') {
          const pdidArray = Object.values(pdid);
          const pdidBuffer = Buffer.from(pdidArray);
          const pdidHex = pdidBuffer.toString('hex');
          navigate(`/drugs/details/${pdidHex}?field=${selectedResultField}`);
        } else {
          console.error('Invalid pdid format:', pdid);
        }
      } catch (error) {
        console.error('Error converting pdid to hex:', error);
      }
    };
    const navigateToDetailsSupplements = (uuid) => {
      try {
        if (uuid && typeof uuid === 'object') {
          const uuidArray = Object.values(uuid);
          const uuidBuffer = Buffer.from(uuidArray);
          const uuidHex = uuidBuffer.toString('hex');
          navigate(`/herbalsupplements/details/${uuidHex}`);
        } else {
          console.error('Invalid uuid format:', uuid);
        }
      } catch (error) {
        console.error('Error converting uuid to hex:', error);
      }
    };
    
    return (
      <Container className="drug-search-container">
        <div id="drug_balancer_logo" className="text-center mb-4">
        {/* <img src="/images/drug_balancer_logo.png" alt="Drug Balancer Logo" /> */}
        <img src="/images/black-transparent.png" alt="Drug Balancer Logo" style={{ height: '100px' }} />
        </div>

        <Card className="search-card mb-3">
          <Card.Body>
          <Tabs
              activeKey={searchMode}
              onSelect={handleSearchModeChange}
              className="mb-3 search-mode-tabs"
            >
              <Tab eventKey="drugs" title={<><img className="mx-1" src="/images/drug_info_small_icon.png" alt="Drugs" /> {t('drugs')}</>} />
              <Tab eventKey="interactions" title={<><img className="mx-1" src="/images/herb_supp_small_icon.png" alt="Herbal Supplements" /> {t('herbalSupplements')}</>} />
            </Tabs>
            <Form>
              <div id="custom-search-input">
                <InputGroup>
                  <Form.Control
                    id="input-search"
                    type="text"
                    value={query}
                    onChange={handleSearchChange}
                    placeholder={t('enterSearchQuery')}
                    className="form-control input-md"
                    autoComplete="off"
                  />
                  {query && (
                    <Button variant="outline-secondary" onClick={() => setQuery('')}>
                      <FaTimes />
                    </Button>
                  )}
                  <Button variant="outline-secondary">
                    <FaSearch />
                  </Button>
                </InputGroup>
              </div>
            </Form>
            {isLoading && <div className="text-center mt-3">{t('loading')}...</div>}
            {noResults && query && (
              <p id="lbl-noresults" className="mt-3 text-center text-danger no-results">
                {t('noResultsFound')} "{query}".
              </p>
            )}
            {query && Object.keys(groupedResults).length > 0 && (
              <Card className="mt-3 search-results">
                <ListGroup variant="flush">
                  {Object.entries(groupedResults).map(([field, items], index) => (
                    <ListGroup.Item key={index} className="p-0">
                      <h6 className="suggest-list-name bg-light p-2 mb-0">
                        {t(fieldNameTranslations[field] || field)}
                      </h6>
                      <ListGroup variant="flush">
                        {items.map((item, i) => (
                          <ListGroup.Item
                            key={i}
                            action
                            onClick={() => handleSelectResult(item)}
                            className="py-2 px-3"
                          >
                                                        {highlightMatch(item.name, query)}
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
            )}
          </Card.Body>
        </Card>

        {!isLoading && (noResults || (!query && !selectedResult)) && (
          <Card className="mt-3 search-tips">
            <Card.Body>
              <h6><FaInfoCircle /> {t('searchingTips')}</h6>
              <small>
              <ul>
                <li>{t('searchModeHint')}</li>
                <li>{t('checkSpelling')}</li>
                <li>{t('typeLetterByLetter')}</li>
                <li>{t('selectCorrectTab')}</li>
                <li>{t('couldNotFind')} <a href="#contact">{t('letUsKnow')}</a></li>
              </ul>
              </small>
            </Card.Body>
          </Card>
        )}

        {selectedResult && (
          <Card className="mt-4 result-card mb-3">
            <Card.Header as="h4" className="text-center text-white result-card-header">
              {`${selectedResult.length} ${searchMode === 'drugs' ? t('drug/s') : t('herbalSupplement/s')} ${t('foundIn')} ${t(fieldNameTranslations[selectedResultField])}: ${selectedResult[0][selectedResultField]}`}
            </Card.Header>
            <ListGroup variant="flush">
              {selectedResult.map((detail, index) => (
                <ListGroup.Item key={index} className="drug-detail-item">
                  {searchMode === 'drugs' ? (
                    <DrugDetail detail={detail} navigateToDetails={navigateToDetails} t={t} />
                  ) : (
                    <SupplementDetail detail={detail} navigateToDetailsSupplements={navigateToDetailsSupplements} t={t} />
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
        )}

        {!query && !selectedResult && !noResults && (
          <div id="drug_balancer_landing" className="landing-content mt-4 mb-2">
            <div className="row">
              <div className="col-sm-12 text-center">
                <p className=" text-secondary">{t('everythingYouWantedToKnow')}</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-3 col-sm-6 text-center">
                <img src="/images/drug_info_icon.png" alt="Drug Info" />
                <h6 style={{ color: '#3E50B4' }} className="title-text mt-3">{t('drugInfo')}</h6>
                <small className="desc-text">{t('drugInfoDesc')}</small>
              </div>
              <div className="col-md-3 col-sm-6 text-center">
                <img src="/images/contraindication_icon.png" alt="Contraindications" />
                <h6 style={{ color: '#3E50B4' }} className="title-text mt-3">{t('contraindications')}</h6>
                <small className="desc-text">{t('contraindicationsDesc')}</small>
              </div>
              <div className="col-md-3 col-sm-6 text-center">
                <img src="/images/nutrient_depleted_icon.png" alt="Nutrients Depleted" />
                <h6 style={{ color: '#3E50B4' }} className="title-text mt-3">{t('nutrientsDepleted')}</h6>
                <small className="desc-text">{t('nutrientsDepletedDesc')}</small>
              </div>
              <div className="col-md-3 col-sm-6 text-center">
                <img src="/images/food_sources_icon.png" alt="Food Sources" />
                <h6 style={{ color: '#3E50B4' }} className="title-text mt-3">{t('foodSources')}</h6>
                <small className="desc-text">{t('foodSourcesDesc')}</small>
              </div>
            </div>
          </div>
        )}
      </Container>
    );
  };

  const DrugDetail = ({ detail, navigateToDetails, t }) => (
    <>
      <Row>
        <Col md={8}>
        <p><strong>{t('brandName')}:</strong> {detail.brand_name}</p>
          <p><strong>{t('family')}:</strong> {detail.family_name}</p>
          <p><strong>{t('category')}:</strong> {detail.category_name}</p>
          <p><strong>{t('indication')}:</strong> {detail.indication}</p>
          <p><strong>{t('genericName')}:</strong> {detail.generic_name}</p>
        </Col>
        <Col md={4} className="d-flex flex-column align-items-end">
          <Button variant="primary" onClick={() => navigateToDetails(detail.pdid)}>
            {t('viewDetails')}
          </Button>
          <div className="drug-icons mt-2">
            <div><img src="/images/ci_small_icon.png" alt="Contraindications" /> <span>{detail.interactions_count}</span></div>
            <div><img src="/images/nd_small_icon.png" alt="Nutrients Depleted" /> <span>{detail.nutrients_depleted_count}</span></div>
          </div>
        </Col>
      </Row>
    </>
  );

  const SupplementDetail = ({ detail, navigateToDetailsSupplements, t }) => (
    <>
      <Row>
        <Col md={8}>
          <p><strong>{t('botanicalName')}:</strong> {detail.botanical_name}</p>
          <p><strong>{t('chineseName')}:</strong> {detail.chinese_name}</p>
          <p><strong>{t('additionalNames')}:</strong> {detail.additional_names}</p>
          <p><strong>{t('partInUse')}:</strong> {detail.part_in_use}</p>
          <p><strong>{t('consumptionType')}:</strong> {detail.consumption_type}</p>
          <p><strong>{t('usageRestrictions')}:</strong> {detail.usage_restrictions}</p>
          <p><strong>{t('pregnancyBreastfeeding')}:</strong> {detail.pregnancy_breastfeeding}</p>
          <p><strong>{t('description')}:</strong> {detail.description}</p>
        </Col>
        <Col md={4} className="d-flex flex-column align-items-end">
          <Button variant="primary" onClick={() => navigateToDetailsSupplements(detail.uuid)}>
            {t('viewDetails')}
          </Button>
          <div className="drug-icons mt-2">
            <div>
            <div><img src="/images/ci_small_icon.png" alt="Contraindications" /> <span>{detail.interactions_count}</span></div>
            <span>{detail.interactions_count}</span></div>
          </div>
        </Col>
      </Row>
    </>
  );

  export default DrugSearch;
