import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useAuth } from './AuthContext';
import { ui } from './firebase';
import { functions } from './firebase';
import { httpsCallable } from 'firebase/functions';
import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';

const Login = () => {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignupSuccess = async (user) => {
    try {
      const sendWelcomeEmail = httpsCallable(functions, 'sendWelcomeEmail');
      await sendWelcomeEmail({
        email: user.email,
        displayName: user.displayName
      });
    } catch (error) {
      console.error('Error sending welcome email:', error);
    }
  };

  useEffect(() => {
    if (!loading && currentUser) {
      const intendedPath = location.state?.from || '/';
      navigate(intendedPath, { replace: true });
    } else if (!loading && !currentUser) {
      const uiConfig = {
        signInOptions: [
          {
            provider: EmailAuthProvider.PROVIDER_ID,
            requireDisplayName: true
          },
          GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
          signInSuccessWithAuthResult: (authResult) => {
            if (authResult.additionalUserInfo.isNewUser) {
              handleSignupSuccess(authResult.user);
            }
            return false;
          }
        },
        signInSuccessUrl: '/',
        tosUrl: '/terms-of-service',
        privacyPolicyUrl: '/privacy-policy',
        signInFlow: 'popup'
      };
      
      ui.start('#firebaseui-auth-container', uiConfig);
    }
  }, [currentUser, loading, navigate, location]);

  if (loading || currentUser) {
    return null;
  }

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={{ span: 6, offset: 3 }}>
          <div id="firebaseui-auth-container"></div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
