import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className="container py-3">
            <main>
                <section className="mb-5">
                    <h2 className="text-center mb-4">{t('aboutUs')}</h2>
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <p className="text-right mb-3">{t('aboutCompanyDescription')}</p>
                            <p className="text-right mb-3">{t('aboutCompanyBelief')}</p>
                            <p className="text-right mb-3">{t('aboutCompanyMission')}</p>
                            <p className="text-right">{t('aboutCompanyGoal')}</p>
                        </div>
                    </div>
                </section>
                <section className="team-section">
                    <h2 className="text-center mb-4">{t('ourTeam')}</h2>
                    <div className="row">
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <img 
                                    src="/images/amir_perelman.jpg" 
                                    alt={t('amirPerelmanAlt')} 
                                    className="card-img-top" 
                                    style={{ width: '100%', height: 'auto', maxWidth: '300px', objectFit: 'contain', margin: '1rem auto 0' }}
                                />
                                <div className="card-body text-right">
                                    <h3 className="card-title">{t('amirPerelmanName')}</h3>
                                    <h4 className="card-subtitle mb-2 text-muted">{t('founderCEO')}</h4>
                                    <p className="card-text">{t('amirPerelmanDescription')}</p>
                                    <a href="https://www.perelmantrade.com/" className="btn btn-primary">{t('moreInfo')}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-4">
                            <div className="card h-100">
                                <img 
                                    src="/images/inbal_liv_perez.png" 
                                    alt={t('inbalLivPerezAlt')} 
                                    className="card-img-top" 
                                    style={{ width: '100%', height: 'auto', maxWidth: '300px', objectFit: 'contain', margin: '1rem auto 0' }}
                                />
                                <div className="card-body text-right">
                                    <h3 className="card-title">{t('inbalLivPerezName')}</h3>
                                    <h4 className="card-subtitle mb-2 text-muted">{t('dataManagerNaturopath')}</h4>
                                    <p className="card-text">{t('inbalLivPerezDescription')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default About;