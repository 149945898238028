import React from 'react';
import { ListGroup, Button, Collapse, Alert, Badge } from 'react-bootstrap';
import { FaUtensils, FaChevronUp, FaChevronDown, FaInfoCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Nutrients = ({ nutrients, openNutrients, setOpenNutrients }) => {
  const { t } = useTranslation();

  return (
    <ListGroup.Item>
      <div className="d-flex justify-content-between align-items-center">
        <h6><img src="/images/nd_small_icon.png" alt="Nutrients Depleted" /> {t('nutrientsDepleted')}           
        </h6>
        <div className="d-flex align-items-center">
          <span className="me-2 section-total">{Object.values(nutrients).flat().length}</span>
          <Button
            variant="link"
            onClick={() => setOpenNutrients(!openNutrients)}
            aria-controls="collapse-nutrients"
            aria-expanded={openNutrients}
          >
            {openNutrients ? <FaChevronUp /> : <FaChevronDown />}
          </Button>
        </div>
      </div>
      <Collapse in={openNutrients}>
        <div id="collapse-nutrients">
          <Alert variant="info" className="w-100">
            <FaInfoCircle /> {t('longTermUse')}
            <br />
            <FaInfoCircle /> {t('medicationsNotOral')}
          </Alert>
          {Object.entries(nutrients).map(([familyName, nutrientNames], index) => (
            familyName !== 'undefined' && (
              <div key={index} className="w-100 mb-2">
                <strong>{familyName}</strong>
                <div className="d-flex flex-wrap">
                  {nutrientNames.map((nutrient, i) => (
                    <Badge key={i} bg="secondary" className="m-1">
                      {nutrient}
                    </Badge>
                  ))}
                </div>
              </div>
            )
          ))}
        </div>
      </Collapse>
    </ListGroup.Item>
  );
};

export default Nutrients;