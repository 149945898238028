import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ListGroup, Container, Row, Col, Button, Modal } from 'react-bootstrap';

const DataHierarchy = () => {
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [selectedTable, setSelectedTable] = React.useState(null);

  const tables = [
    { name: 'drug', relationships: [
      { table: 'drug_family', field: 'drug_family_bridge.drug_id' }, 
      { table: 'user_profile', field: 'profile_drug_bridge.drug_id' }, 
      { table: 'yarpa_code_pdid_bridge', field: 'yarpa_code_pdid_bridge.pdid' }
    ]},
    { name: 'drug_family', relationships: [
      { table: 'drug', field: 'drug_family_bridge.family_id' }, 
      { table: 'nutrient', field: 'family_nutrients_depleted_bridge.family_id' }, 
      { table: 'drug_family_interaction', field: 'drug_family_interaction.family_id' }
    ]},
    { name: 'nutrient', relationships: [
      { table: 'drug_family', field: 'family_nutrients_depleted_bridge.nutrient_id' }, 
      { table: 'nutrient_source', field: 'nutrient_source.nutrient_id' }
    ]},
    { name: 'interaction', relationships: [
      { table: 'drug_family_interaction', field: 'drug_family_interaction.interaction_id' }
    ]},
    { name: 'drug_family_interaction', relationships: [
      { table: 'drug_family', field: 'drug_family_interaction.family_id' }, 
      { table: 'interaction', field: 'drug_family_interaction.interaction_id' }, 
      { table: 'drug_family_interaction_details', field: 'drug_family_interaction_details.drug_family_interaction_id' }
    ]},
    { name: 'drug_family_interaction_details', relationships: [
      { table: 'drug_family_interaction', field: 'drug_family_interaction_details.drug_family_interaction_id' }, 
      { table: 'drug_family_interaction_details_research', field: 'drug_family_interaction_details_research.drug_family_interaction_details_id' }
    ]},
    { name: 'drug_family_interaction_details_research', relationships: [
      { table: 'drug_family_interaction_details', field: 'drug_family_interaction_details_research.drug_family_interaction_details_id' }
    ]},
    { name: 'user_account', relationships: [
      { table: 'user_profile', field: 'user_profile.user_account_id' }, 
      { table: 'cg_transaction', field: 'cg_transaction.user_account_auth_id' }, 
      { table: 'coupon_user_bridge', field: 'coupon_user_bridge.user_auth_id' }
    ]},
    { name: 'user_profile', relationships: [
      { table: 'user_account', field: 'user_profile.user_account_id' }, 
      { table: 'drug', field: 'profile_drug_bridge.profile_id' }
    ]},
    { name: 'cg_transaction', relationships: [
      { table: 'user_account', field: 'cg_transaction.user_account_auth_id' }
    ]},
    { name: 'yarpa_code_pdid_bridge', relationships: [
      { table: 'drug', field: 'yarpa_code_pdid_bridge.pdid' }
    ]},
    { name: 'coupon', relationships: [
      { table: 'coupon_user_bridge', field: 'coupon_user_bridge.coupon_code' }
    ]},
    { name: 'coupon_user_bridge', relationships: [
      { table: 'coupon', field: 'coupon_user_bridge.coupon_code' }, 
      { table: 'user_account', field: 'coupon_user_bridge.user_auth_id' }
    ]},
    { name: 'nutrient_source', relationships: [
      { table: 'nutrient', field: 'nutrient_source.nutrient_id' }
    ]}
  ];

  const handleTableClick = (table) => {
    setSelectedTable(table);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedTable(null);
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h2>Data Hierarchy</h2>
          <ListGroup>
            {tables.map((table, index) => (
              <ListGroup.Item key={index} action onClick={() => handleTableClick(table)}>
                <strong>{table.name}</strong> (relationships: {table.relationships.map(rel => rel.table).join(', ')})
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
      {selectedTable && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedTable.name} Relationships</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Relationships:</h5>
            <ul>
              {selectedTable.relationships.map((rel, index) => (
                <li key={index}>
                  <strong>{rel.table}</strong>: linked by field <code>{rel.field}</code>
                </li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => navigate(`/edit/${selectedTable.name}`)}>
              Edit {selectedTable.name}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default DataHierarchy;
