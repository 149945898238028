import React, { useEffect, useState } from 'react';
import { Alert, Button, Nav } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const SubscriptionStatus = ({ user }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const getViewLimits = httpsCallable(functions, 'checkviewlimits');
        const response = await getViewLimits();
        setStatus(response.data);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    if (user) {
      fetchStatus();
    }
  }, [user]);
  
  if (!status) return null;

  const handleClick = () => {
    navigate('/account');
  };

  return (
    <Nav.Item>
      <Alert 
        variant={status.isSubscribed ? "success" : (status.isTrial ? "info" : "warning")}
        className="subscription-alert mb-0 d-inline-flex align-items-center py-1 px-2 me-2"
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <small>
          {status.isSubscribed 
            ? t('activeSubscription') 
            : (status.isTrial 
                ? t('activeTrial') 
                : t('inactiveSubscription')
            )}
        </small>
      </Alert>
    </Nav.Item>
  );
};

export default SubscriptionStatus;